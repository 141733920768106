import React from 'react';

const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const Dashboard = React.lazy(() => import('./views/pages/dashboard/Dashboard'));
const PotentialCandidate = React.lazy(() =>
  import('./views/pages/potentialCandidate/PotentialCandidate'),
);
const JobManagerment = React.lazy(() => import('./views/pages/jobManagerment/JobManagerment'));
const CvManagerment = React.lazy(() => import('./views/pages/cvManagerment/CvManagerment'));
const ConfigManagerment = React.lazy(() => import('./views/pages/config/Config'));
const BodManagement = React.lazy(() => import('./views/pages/bodManagerment/BodManagerment'));
const ContactUsManagement = React.lazy(() => import('./views/pages/contactUs/ContactUs'));
const IntroductionManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagement'),
);
const IntroductionDetailManagement = React.lazy(() =>
  import('./views/pages/introductionManagement/IntroductionManagementDetail'),
);

const NewManagement = React.lazy(() => import('./views/pages/newManagement/NewManagement'));
const ServiceManagement = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagement'),
);
const ServiceManagementDetail = React.lazy(() =>
  import('./views/pages/serviceManagement/ServiceManagementDetail'),
);
const ServiceTypeManagement = React.lazy(() =>
  import('./views/pages/serviceTypeManagement/ServiceTypeManagement'),
);
const ServiceTypeManagementDetail = React.lazy(() =>
  import('./views/pages/serviceTypeManagement/ServiceTypeManagementDetail'),
);
const allRoutes = [
  {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/potential-candidate',
    name: 'Potential Candidate',
    component: PotentialCandidate,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUsManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/job-management',
    name: 'Jobs',
    component: JobManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/cv-management',
    name: 'CV',
    component: CvManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/config',
    name: 'Config',
    component: ConfigManagerment,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/bod-management',
    name: 'BOD',
    component: BodManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management',
    name: 'Service',
    component: ServiceManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-management/:id',
    name: 'Service',
    component: ServiceManagementDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-type-management',
    name: 'Service Type',
    component: ServiceTypeManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/service-type-management/:id',
    name: 'Service Type',
    component: ServiceTypeManagementDetail,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: IntroductionManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/introduction/:id',
    name: 'Introduction',
    component: IntroductionDetailManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/news-management',
    name: 'News',
    component: NewManagement,
    exact: true,
    isPrivate: true,
    hasLayout: true,
  },
  {
    path: '/login',
    key: 'Login',
    name: 'Login',
    component: Login,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/register',
    key: 'Register',
    name: 'Register',
    component: Register,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/404',
    key: 'Page404',
    name: 'Page404',
    component: Page404,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
  {
    path: '/500',
    key: 'Page500',
    name: 'Page500',
    component: Page500,
    exact: true,
    isPrivate: false,
    hasLayout: false,
  },
];

export const routes = allRoutes.reduce((obj, item) => {
  const temp = { ...obj };
  if (item.key) temp[item.key.toLowerCase()] = item;
  return temp;
}, {});

export default allRoutes;
