const domain = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const defaultUrl = `${domain}`;
export const getAuthURI = `${defaultUrl}`;
export const getJobURI = `${defaultUrl}/jobs`;
export const upsertJobURI = `${defaultUrl}/jobs`;
export const detailJobURI = id => `${defaultUrl}/jobs/${id}`;
export const updateJobStatusURI = id => `${defaultUrl}/jobs/update-status/${id}`;
export const getJobCategoryURI = `${defaultUrl}/categories`;
export const createJobCategoryURI = `${defaultUrl}/categories`;
export const getCVURI = `${defaultUrl}/cv`;
export const viewCVURI = path => `${defaultUrl}${path}`;
export const updateCVStatusURI = id => `${defaultUrl}/cv/update-status/${id}`;
export const getContactURI = `${defaultUrl}/contacts`;
export const updateContactStatusURI = id => `${defaultUrl}/contacts/update-status/${id}`;

export const getBlockURI = `${defaultUrl}/blocks`;
export const getBodURI = `${defaultUrl}/bods`;
export const upsertBodURI = `${defaultUrl}/bods`;
export const detailBodURI = id => `${defaultUrl}/bods/${id}`;
export const updateBodStatusURI = id => `${defaultUrl}/bods/update-status/${id}`;
export const viewAvatar = path => `${defaultUrl}${path}`;
export const searchBodsURI = `${defaultUrl}/bods/searchBOD`;

export const getIntroductionURI = `${defaultUrl}/introductions`;
export const upsertIntroductionURI = `${defaultUrl}/introductions`;
export const updateIntroductionStatusURI = id => `${defaultUrl}/introductions/${id}`;
export const detailIntroductionURI = id => `${defaultUrl}/introductions/getIntroductionAdmin/${id}`;
export const updateStatusIntroductionStatusURI = id =>
  `${defaultUrl}/introductions/update-status/${id}`;
export const viewImage = path => `${defaultUrl}${path}`;
export const searchIntroductionsURI = `${defaultUrl}/introductions/searchIntroduction`;

export const getNewURI = `${defaultUrl}/news`;
export const upsertNewURI = `${defaultUrl}/news`;
export const updateNewStatusURI = id => `${defaultUrl}/news/${id}`;
export const viewBanner = path => `${defaultUrl}${path}`;
export const searchNewsURI = `${defaultUrl}/news/searchNews`;

export const serviceURI = `${defaultUrl}/services`;
export const detailServiceURI = id => `${defaultUrl}/services/${id}`;
export const updateServiceStatusURI = id => `${defaultUrl}/services/update-status/${id}`;
export const viewServiceURI = path => `${defaultUrl}${path}`;

export const candidateURI = `${defaultUrl}/candidates`;

export const getConfigURI = `${defaultUrl}/configs`;
export const uploadBannerURI = `${defaultUrl}/configs/uploadBanner`;
export const uploadFileURI = `${defaultUrl}/uploadFile`;
export const getProcessURI = `${defaultUrl}/configs/getProcess`;

export const serviceCategoryURI = `${defaultUrl}/services_categories`;

export const getListIotProjectURI = `${defaultUrl}/getListIotProject`;
export const upsertIotProjectURI = `${defaultUrl}/storeIotProject`;

export const getCustomerCategoryURI = `${defaultUrl}/customer-categories`;

export const serviceTypeURI = `${defaultUrl}/services_categories_admin`;
export const detailServiceTypeURI = id => `${defaultUrl}/services_categories/${id}`;
export const updateServiceTypeStatusURI = id =>
  `${defaultUrl}/services_categories/update-status/${id}`;
