import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './slices/AuthSlice';
import SidebarReducer from './slices/SidebarSlice';
import JobReducer from './slices/JobSlice';
import CategoryReducer from './slices/JobCatergoriesSlice';
import MessageReducer from './slices/MessageSlice';
import JobItemReducer from './slices/JobItemSlice';
import cvReducer from './slices/CVSlice';
import contactReducer from './slices/ContactSlice';
import blockReducer from './slices/BlockSlice';
import bodReducer from './slices/BodSlice';
import bodItemReducer from './slices/BodItemSlice';
import newReducer from './slices/NewSlice';
import newItemReducer from './slices/NewItemSlice';
import serviceReducer from './slices/ServiceSlice';
import serviceItemReducer from './slices/ServiceItemSlice';
import serviceCategoryReducer from './slices/ServiceCategorySlice';
import potentialCandidateReducer from './slices/PotentialCandidateSlice';
import configReducer from './slices/ConfigSlice';
import introductionItemReducer from './slices/IntroductionItemSlice';
import introductionReducer from './slices/IntroductionSlice';
import serviceTypeReducer from './slices/ServiceTypeSlice';
import serviceItemTypeReducer from './slices/ServiceItemTypeSlice';

export default configureStore({
  reducer: {
    auth: AuthReducer,
    sidebar: SidebarReducer,
    job: JobReducer,
    category: CategoryReducer,
    message: MessageReducer,
    jobItem: JobItemReducer,
    cv: cvReducer,
    contact: contactReducer,
    block: blockReducer,
    bod: bodReducer,
    bodItem: bodItemReducer,
    new: newReducer,
    newItem: newItemReducer,
    service: serviceReducer,
    serviceItem: serviceItemReducer,
    serviceCategory: serviceCategoryReducer,
    potentialCandidate: potentialCandidateReducer,
    config: configReducer,
    introductionItem: introductionItemReducer,
    introduction: introductionReducer,
    serviceType: serviceTypeReducer,
    serviceTypeItem: serviceItemTypeReducer,
  },
});
